import React, { useEffect, useState } from 'react'

export default function PopUpPoliticas() {
    const [showPopup, setShowPopup] = useState(false);
    
        useEffect(() => {
            const timer = setTimeout(() => {
            setShowPopup(true);
        }, 1500);
    
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {showPopup && (
                 <div className="fixed left-2 bottom-2 bg-white shadow-2xl rounded-lg p-4 w-80 z-50">
                    <button
                        onClick={() => setShowPopup(false)}
                        className="absolute top-2 right-2 text-gray-700"
                    >
                        <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    <p className="text-[#171923] text-center text-sm font-semibold">AVISO IMPORTANTE</p>
                    <p className='text-[#171923] text-justify text-sm mt-2 font-normal'>
                        Hemos actualizado nuestra Política de Tratamiento de Datos Personales.
                        Conózcala <a className='text-[#51C1E1]' href='https://www.finmaq.com.co/politicas-de-tratamiento-datos' target="_blank" rel="noopener noreferrer"> aquí</a>.
                    </p>
                </div>
            )}
        </div>
    )
}