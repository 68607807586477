import React, { useEffect } from "react";
import Header from "../components/NavBar";
import Footer from "../components/Footer";

//TODO: Formatear de forma correcta.

/* Su política de privacidad debe:

?Estar vinculada en la página de listado de la tienda de tu app en Play Console y dentro de la propia app.

?Hacer referencia a la entidad (por ejemplo, desarrollador, empresa) mencionada en el listado de Google Play de la aplicación o en la propia aplicación.

?Incluir un etiquetado claro como política de privacidad.

?Ser legible en un navegador estándar sin plug-ins ni manejadores especiales (no PDFs).

?Estar disponible en una URL activa, de acceso público y sin geolocalización.

?No ser editable.

?Revelar de forma exhaustiva cómo su aplicación accede, recopila, utiliza y comparte los datos de los usuarios. Esto debe incluir:

?Revelar los tipos de datos personales y confidenciales de los usuarios a los que accede, recopila, utiliza y comparte la aplicación; y las partes con las que se comparten los datos personales o confidenciales de los usuarios.

?Procedimientos seguros de tratamiento de datos personales y sensibles de los usuarios.

?Política de conservación y eliminación de datos del desarrollador.
*/

/*
<strong>Privacy Policy</strong> <p>
                  Finmaq built the Finmaq Comerciales app as
                  a Commercial app. This SERVICE is provided by
                  Finmaq  and is intended for use as
                  is.
                </p> <p>
                  This page is used to inform visitors regarding our
                  policies with the collection, use, and disclosure of Personal
                  Information if anyone decided to use our Service.
                </p> <p>
                  If you choose to use our Service, then you agree to
                  the collection and use of information in relation to this
                  policy. The Personal Information that we collect is
                  used for providing and improving the Service. We will not use or share your information with
                  anyone except as described in this Privacy Policy.
                </p> <p>
                  The terms used in this Privacy Policy have the same meanings
                  as in our Terms and Conditions, which are accessible at
                  Finmaq Comerciales unless otherwise defined in this Privacy Policy.
                </p> <p><strong>Information Collection and Use</strong></p> <p>
                  For a better experience, while using our Service, we
                  may require you to provide us with certain personally
                  identifiable information, including but not limited to email, phone, financial information, names, id. The information that
                  we request will be retained by us and used as described in this privacy policy.
                </p> <div><p>
                    The app does use third-party services that may collect
                    information used to identify you.
                  </p> <p>
                    Link to the privacy policy of third-party service providers used
                    by the app
                  </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Log Data</strong></p> <p>
                  We want to inform you that whenever you
                  use our Service, in a case of an error in the app
                  we collect data and information (through third-party
                  products) on your phone called Log Data. This Log Data may
                  include information such as your device Internet Protocol
                  (“IP”) address, device name, operating system version, the
                  configuration of the app when utilizing our Service,
                  the time and date of your use of the Service, and other
                  statistics.
                </p> <p><strong>Cookies</strong></p> <p>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </p> <p>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third-party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </p> <p><strong>Service Providers</strong></p> <p>
                  We may employ third-party companies and
                  individuals due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                  We want to inform users of this Service
                  that these third parties have access to their Personal
                  Information. The reason is to perform the tasks assigned to
                  them on our behalf. However, they are obligated not to
                  disclose or use the information for any other purpose.
                </p> <p><strong>Security</strong></p> <p>
                  We value your trust in providing us your
                  Personal Information, thus we are striving to use commercially
                  acceptable means of protecting it. But remember that no method
                  of transmission over the internet, or method of electronic
                  storage is 100% secure and reliable, and we cannot
                  guarantee its absolute security.
                </p> <p><strong>Links to Other Sites</strong></p> <p>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us.
                  Therefore, we strongly advise you to review the
                  Privacy Policy of these websites. We have
                  no control over and assume no responsibility for the content,
                  privacy policies, or practices of any third-party sites or
                  services.
                </p> <p><strong>Children’s Privacy</strong></p> <div><p>
                    These Services do not address anyone under the age of 13.
                    We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case
                    we discover that a child under 13 has provided
                    us with personal information, we immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact us so that
                    we will be able to do the necessary actions.
                  </p></div> <!----> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                  We may update our Privacy Policy from
                  time to time. Thus, you are advised to review this page
                  periodically for any changes. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p> <p>This policy is effective as of 2023-01-10</p> <p><strong>Contact Us</strong></p> <p>
                  If you have any questions or suggestions about our
                  Privacy Policy, do not hesitate to contact us at tech@finmaq.com.co.
                </p> 
*/

/*
<strong contenteditable="false">Terms &amp; Conditions</strong> <p contenteditable="false">
                  By downloading or using the app, these terms will
                  automatically apply to you – you should make sure therefore
                  that you read them carefully before using the app. You’re not
                  allowed to copy or modify the app, any part of the app, or
                  our trademarks in any way. You’re not allowed to attempt to
                  extract the source code of the app, and you also shouldn’t try
                  to translate the app into other languages or make derivative
                  versions. The app itself, and all the trademarks, copyright,
                  database rights, and other intellectual property rights related
                  to it, still belong to Finmaq.
                </p> <p contenteditable="false">
                  Finmaq is committed to ensuring that the app is
                  as useful and efficient as possible. For that reason, we
                  reserve the right to make changes to the app or to charge for
                  its services, at any time and for any reason. We will never
                  charge you for the app or its services without making it very
                  clear to you exactly what you’re paying for.
                </p> <p contenteditable="false">
                  The Finmaq Comerciales app stores and processes personal data that
                  you have provided to us, to provide our
                  Service. It’s your responsibility to keep your phone and
                  access to the app secure. We therefore recommend that you do
                  not jailbreak or root your phone, which is the process of
                  removing software restrictions and limitations imposed by the
                  official operating system of your device. It could make your
                  phone vulnerable to malware/viruses/malicious programs,
                  compromise your phone’s security features and it could mean
                  that the Finmaq Comerciales app won’t work properly or at all.
                </p> <div   contenteditable="false" contenteditable="false"><p contenteditable="false">
                    The app does use third-party services that declare their
                    Terms and Conditions.
                  </p> <p contenteditable="false">
                    Link to Terms and Conditions of third-party service
                    providers used by the app
                  </p> <ul contenteditable="false"><li contenteditable="false"><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer" contenteditable="false">Google Play Services</a></li><!----><li contenteditable="false"><a href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer" contenteditable="false">Google Analytics for Firebase</a></li><li contenteditable="false"><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer" contenteditable="false">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p contenteditable="false">
                  You should be aware that there are certain things that
                  Finmaq will not take responsibility for. Certain
                  functions of the app will require the app to have an active
                  internet connection. The connection can be Wi-Fi or provided
                  by your mobile network provider, but Finmaq
                  cannot take responsibility for the app not working at full
                  functionality if you don’t have access to Wi-Fi, and you don’t
                  have any of your data allowance left.
                </p> <p contenteditable="false"></p> <p contenteditable="false">
                  If you’re using the app outside of an area with Wi-Fi, you
                  should remember that the terms of the agreement with your
                  mobile network provider will still apply. As a result, you may
                  be charged by your mobile provider for the cost of data for
                  the duration of the connection while accessing the app, or
                  other third-party charges. In using the app, you’re accepting
                  responsibility for any such charges, including roaming data
                  charges if you use the app outside of your home territory
                  (i.e. region or country) without turning off data roaming. If
                  you are not the bill payer for the device on which you’re
                  using the app, please be aware that we assume that you have
                  received permission from the bill payer for using the app.
                </p> <p contenteditable="false">
                  Along the same lines, Finmaq cannot always take
                  responsibility for the way you use the app i.e. You need to
                  make sure that your device stays charged – if it runs out of
                  battery and you can’t turn it on to avail the Service,
                  Finmaq cannot accept responsibility.
                </p> <p contenteditable="false">
                  With respect to Finmaq’s responsibility for your
                  use of the app, when you’re using the app, it’s important to
                  bear in mind that although we endeavor to ensure that it is
                  updated and correct at all times, we do rely on third parties
                  to provide information to us so that we can make it available
                  to you. Finmaq accepts no liability for any
                  loss, direct or indirect, you experience as a result of
                  relying wholly on this functionality of the app.
                </p> <p contenteditable="false">
                  At some point, we may wish to update the app. The app is
                  currently available on Android – the requirements for the 
                  system(and for any additional systems we
                  decide to extend the availability of the app to) may change,
                  and you’ll need to download the updates if you want to keep
                  using the app. Finmaq does not promise that it
                  will always update the app so that it is relevant to you
                  and/or works with the Android version that you have
                  installed on your device. However, you promise to always
                  accept updates to the application when offered to you, We may
                  also wish to stop providing the app, and may terminate use of
                  it at any time without giving notice of termination to you.
                  Unless we tell you otherwise, upon any termination, (a) the
                  rights and licenses granted to you in these terms will end;
                  (b) you must stop using the app, and (if needed) delete it
                  from your device.
                </p> <p contenteditable="false"><strong contenteditable="false">Changes to This Terms and Conditions</strong></p> <p contenteditable="false">
                  We may update our Terms and Conditions
                  from time to time. Thus, you are advised to review this page
                  periodically for any changes. We will
                  notify you of any changes by posting the new Terms and
                  Conditions on this page.
                </p> <p contenteditable="false">
                  These terms and conditions are effective as of 2023-01-10
                </p> <p contenteditable="false"><strong contenteditable="false">Contact Us</strong></p> <p contenteditable="false">
                  If you have any questions or suggestions about our
                  Terms and Conditions, do not hesitate to contact us
                  at tech@finmaq.com.co.
                </p>    
*/



export default function AvisoPrivacidad() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="p-5 md:p-20 md:pb-0" contenteditable="false">
        <hr />
        <div className="text-xl md:p-10 font-bold pb-0" contenteditable="false">
          AVISO DE PRIVACIDAD
        </div>

        <div contenteditable="false" className="p-5 md:p-10 pb-0 ">
        FINANCIAMOS SU MAQUINA S.A.S. (en adelante “FinMaq”), en cumplimiento de la Ley 1581 de 2012 y demás
normas concordantes, es responsable del tratamiento de sus datos personales. Los datos personales que
FinMaq solicita tanto en el <strong>aplicativo web cómo en el aplicativo movil (finmaq.com.co | Finmaq
Comerciales)</strong> a través de alguno de nuestros socios comerciales serán utilizados para:

          <br /> <br />
          o Adelantar y cumplir con los trámites precontractuales y
          contractuales. necesarios, en desarrollo de su objeto social. <br />
          o Prospección comercial y perfilamiento. <br />
          o Fines comerciales y de fidelización. <br />
          o Administración y evaluación de Riesgo. <br /> <br />

          <b className="text-lg">Recogida y uso de información</b> <br /> <br />
          Finmaq recoge datos personales como: Nombre, número de identificación, celular, correo, información
financiera, con el fin adelantar procesos de negociación, estudios crediticios, procesos comerciales. Cabe
resaltar, que estos <strong>datos serán recogidos única y exclusivamente con el permiso expreso del titular,</strong> quien
podrá saber en todo momento qué datos han sido guardados en nuestra base de datos.  
          <br /> <br />
          <b className="text-lg">Servicios de terceros</b> <br /> <br />
          Además de los servicios que prestamos directamente, también utilizamos proveedores de servicios de terceros
(denominados colectivamente “Servicios de terceros”) que usan sus propias políticas de privacidad (enumeradas
a continuación) y también pueden proporcionar opciones para rechazar la recolección de datos. Como empresa,
confiamos en todos los servicios de terceros que usamos, y consideramos que sus servicios son importantes
para nuestro funcionamiento efectivo. Puede obtener más información sobre los servicios que utilizamos, y a la
vez sobre aquellos que quizá quiera usar mientras interactúa con nuestros servicios, también le informamos
sobre para qué usamos los servicios y enlazamos a sus políticas de privacidad asociadas. Los servicios de
terceros que usamos:<br /> <br />
          <b>Crashlytics </b>- para la captura y recolección de registros de errores que nos ayuden a identificar bugs en nuestro
software. Este servicio, impulsado por Google Inc., está diseñado para mejorar y corregir errores en la
aplicación. Los datos recogidos para este propósito se transmiten a Metrel en forma anónima. El servicio
determina si se ha producido un error, qué línea de código causó el error y el tipo y sistema operativo del
dispositivo móvil afectado. Estos datos sólo se utilizan para analizar errores en la aplicación y corregirlos en el
futuro. Durante este proceso no se transfiere ningún dato personal. La protección de datos para la analítica de
móviles Fabric se puede ver aquí: http://try.crashlytics.com/terms/privacy-policy.pdf <br /> <br />
          <b>Google analytics from firebase </b>- para la captura y recolección información relevante para analizar el
comportamiento de los usuarios de nuestra app. Este servicio, impulsado por Google Inc., está diseñado para
tener métricas de desempeño y de experiencia de usuario. Durante este proceso no se transfiere ningún dato
personal. La protección de datos para este servicio se puede ver aquí:
https://firebase.google.com/policies/analytics <br /> <br />
          <b>Firebase App Indexing User Data </b>- para la captura y recolección de datos pedidos para hacer una alianza
comercial con nosotros. Este servicio, impulsado por Google Inc., está diseñado para almacenar información y
acceder a ella de forma rápida y confiable. La protección de datos para este servicio se puede ver aquí:
https://firebase.google.com/policies/app-indexing<br /> <br />
          <br /> <br />
          <b className="text-lg">
            Permisos necesarios para el uso de la APP
          </b>{" "}
          <br /> <br />
          <ul>
            <li>
              - <b>Internet</b> - Acceso a servidores de archivos de red y otras
              funcionalidades relacionadas con la red.
            </li>
            <li>
              <b>- Almacenamiento externo de lectura/escritura - </b> Para
              administrar sesiones de la aplicación.
            </li>
          </ul>
          <br /> <br /> Como titular de información tiene derecho a conocer, actualizar y rectificar sus datos personales y, sólo en los
casos en que sea procedente, a suprimirlos o revocar la autorización otorgada para su tratamiento. Si requiere
mayor información para conocer nuestra Política de Tratamiento de Datos ¨Personales y los cambios
sustanciales que se produzcan en ella consulte aquí. Si desea presentar una consulta, reclamo o petición de
información relacionada con la protección de datos personales puede contactarnos al    electrónico
<strong> datos@finmaq.com.co</strong> o comunicarse al celular <strong>321 241 8543</strong> o en la Sede Principal, Carrera 10 # 97a - 13 Bogotá D.C., en el horario de 8:00am a 5:00pm en días hábiles.
        </div>
        <hr />
      </div>
      <Footer />
    </div>
  );
}
